import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Reviews from "../components/Reviews/reviews";

const ReviewForm = (props) => {
	const products = props.data.allShopifyCollection.edges[0].node.products;
	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>
			<Reviews writeOnly products={products} />
		</>
	);
};

export default (props) => (
	<StaticQuery
		query={graphql`
			query reviewPageQuery {
				allShopifyCollection(filter: { title: { in: "Tearribles" } }) {
					edges {
						node {
							title
							products {
								id
								title
								descriptionHtml
								legacyResourceId
								productType
								tags
								handle
								# images {
								#     originalSrc
								#     localFile {
								#         childImageSharp {
								#             fluid {
								#                 ...GatsbyImageSharpFluid_withWebp_noBase64
								#             }
								#         }
								#     }
								# }
								featuredImage {
									originalSrc
									localFile {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp_noBase64
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`}
	>
		{(data) => <ReviewForm data={data} {...props} />}
	</StaticQuery>
);
